<template>
  <div class="aui-wrapper aui-page__login">
    <div class="aui-content__wrapper">
      <main class="aui-content">
        <div class="login-header">
          <h2 class="login-brand">{{logoTitle + '管理系统'}}</h2>
        </div>
        <div class="login-body">
          <h3 class="login-title">登录</h3>
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmitHandle()"
            status-icon>
            <el-form-item prop="phone">
              <el-input v-model.trim="dataForm.phone" placeholder="用户名" autocomplete="off">
                <span slot="prefix" class="el-input__icon">
                  <i class="el-icon-user"></i>
                </span>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model.trim="dataForm.password" type="password" placeholder="密码" autocomplete="off">
                <span slot="prefix" class="el-input__icon">
                  <i class="el-icon-lock"></i>
                </span>
              </el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <el-row :gutter="20">
                <el-col :span="14">
                  <el-input v-model="dataForm.verifyCode" placeholder="验证码">
                    <span slot="prefix" class="el-input__icon">
                      <i slot="prefix" class="el-input__icon el-icon-s-check" style="font-size: 22px"></i>
                    </span>
                  </el-input>
                </el-col>
                <el-col :span="10" class="login-captcha">
                  <div @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- <el-form-item style="text-align: right">
              <el-checkbox v-model="isRemember">
                记住密码
              </el-checkbox>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="dataFormSubmitHandle()" class="w-full">登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="login-footer">杭州皓鑫文化创意有限公司 浙ICP备2022034413号</div>
      </main>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import SIdentify from '@/components/identify.vue'

export default {
  components: {
    SIdentify
  },
  data () {
    // 验证码自定义验证规则
    const validateVerifyCode = (rule, value, callback) => {
      let newVal = value.toLowerCase()
      let identifyStr = this.identifyCode.toLowerCase()
      if (newVal === '') {
        callback(new Error('请输入验证码'))
      } else if (newVal !== identifyStr) {
        console.log('validateVerifycode:', value)
        callback(new Error('验证码不正确!'))
      } else {
        callback()
      }
    }
    return {
      logoTitle: window._config.logoTitle,
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      identifyCode: '',
      isRemember: false,
      dataForm: {
        phone: '',
        password: '',
        verifyCode: '',
      },
      dataRule: {
        phone: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '必填项不能为空', trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, trigger: 'blur', validator: validateVerifyCode }
        ]
      }
    }
  },
  computed: {},
  created () {// 验证码初始化
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  mounted () {
    // this.getCookie()  //首先判断cookie中的值
  },
  methods: {
    //首先判断cookie中的值
    getCookie () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split(";")
        for (var i = 0; i < arr.length; i++) {
          var userKey = arr[i].split("=")
          if (userKey[0].trim() == "userName") {
            this.usermsg.user = userKey[1]
          } else if (userKey[0].trim() == "userPws") {
            this.usermsg.psw = userKey[1]
          } else if (userKey[0].trim() == "isRemember") {
            this.isRemember = Boolean(userKey[1])
          }
        }
      }
    },
    //存储cookie
    setCookie (userName, userPws, days) {
      var curDate = new Date()
      curDate.setTime(curDate.getTime() + 24 * 60 * 60 * 1000 * days)  //设置cookie存储的有效时间
      window.document.cookie = "userName" + "=" + userName + ";path=/;expires=" + curDate.toGMTString()
      window.document.cookie = "userPws" + "=" + userPws + ";path=/;expires=" + curDate.toGMTString()
      window.document.cookie = "isRemember" + "=" + this.isRemember + ";path=/;expires=" + curDate.toGMTString()
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http.post('/userInfo/login', this.dataForm).then(({ data: res }) => {
          // if (this.isRemember == true) {
          //   this.setCookie(this.dataForm.phone, this.dataForm.password, 7)  //存储cookie
          // } else {
          //   this.setCookie("", "", -1)  //清除cookie
          // }
          // this.$store.commit('SET_USER', res.data)
          localStorage.setItem('userInfo', JSON.stringify(res.data)) //用户信息放入缓存
          // this.$store.dispatch('menu/findConfigs') //刷新字典
          // store.commit('menu/SET_MENU', res.data.permissionArr) //赋值右侧导航栏 vuex
          window._config.dynamicMenuRoutesHasAdded = false  //重新加载右侧列表
          window._config.menuList = res.data.permissionArr
          this.$router.replace({ name: 'home' })
        }).catch(() => {
          this.refreshCode()  //切换验证码
        })
      })
    }, 1000, { 'leading': true, 'trailing': false }),
    // 切换验证码
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 从 o 中生成 l 位随机验证码
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += o[this.randomNum(0, o.length)]
      }
    },
    // 生成 min - max 的随机数
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
  }
}
</script>
<style lang="less" scoped>
.aui-page__login {
  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
  }
  &::before {
    background-image: url('https://boxqn.hzkuqu.com/manage/34d10ccfe3d64e5ea85802db314c4ab6');
    background-size: cover;
  }
  &::after {
    background-color: rgba(38, 50, 56, 0.4);
  }
  .aui-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 50px 20px 150px;
    box-sizing: border-box;
    text-align: center;
    &__wrapper {
      height: 100vh;
      background-color: transparent;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .login-header {
    padding: 20px;
    color: #fff;
    .login-brand {
      margin: 0 0 15px;
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .login-intro {
      padding: 0;
      margin: 0;
      list-style: none;
      > li {
        font-size: 16px;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.6);
        & + li {
          margin-top: 5px;
        }
      }
    }
  }
  .login-body,
  .login-footer {
    width: 350px;
  }
  .login-body {
    padding: 20px 30px;
    background-color: #fff;
    .login-title {
      font-size: 18px;
      font-weight: bold;
      // padding: 20px 0;
      padding-bottom: 20px;
    }
    .el-input__prefix .el-input__icon {
      font-size: 16px;
    }
    .login-captcha {
      height: 40px;
      line-height: 38px;
      > img {
        max-width: 100%;
        cursor: pointer;
      }
    }
    .login-shortcut {
      margin-bottom: 20px;
      &__title {
        position: relative;
        margin: 0 0 15px;
        font-weight: 400;

        &::before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          z-index: 1;
          content: '';
          height: 1px;
          margin-top: -0.5px;
          background-color: #dcdfe6;
          overflow: hidden;
        }
        > span {
          position: relative;
          z-index: 2;
          padding: 0 20px;
          color: rgba(0, 0, 0, 0.3);
          background-color: #fff;
        }
      }
      &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 0;
        > li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 10px;
          font-size: 28px;
        }
      }
    }
    .login-guide {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .login-footer {
    position: absolute;
    bottom: 0;
    padding: 20px;
    color: rgba(255, 255, 255, 0.6);
    p {
      margin: 10px 0;
    }
    a {
      padding: 0 5px;
      color: rgba(255, 255, 255, 0.6);
      &:focus,
      &:hover {
        color: #fff;
      }
    }
  }
  // 右侧垂直风格
  &--right-vertical {
    .aui-content {
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0;
    }
    .login-header {
      flex: 1;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 30px 120px;
      text-align: left;
    }
    .login-body {
      position: relative;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 120px 30px 150px;
      text-align: center;
      .login-guide {
        margin-top: 0;
      }
    }
    .login-footer {
      right: 0;
      color: #606266;
      a {
        color: #606266;
        &:focus,
        &:hover {
          color: #409eff;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .aui-page__login {
    &--right-vertical {
      .login-header {
        padding: 30px;
      }
    }
  }
}
@media (max-width: 767px) {
  .aui-page__login {
    &--right-vertical {
      .login-header {
        .login-brand,
        .login-intro {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .aui-page__login {
    .login-body,
    .login-footer {
      width: 100%;
    }
    .login-captcha {
      text-align: left;
      > img {
        width: 136px;
      }
    }
    &--right-vertical {
      .login-header {
        display: none;
      }
    }
  }
}
</style>
